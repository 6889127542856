window.domReady(() => {
    let addedToPcpCartModalElement = document.getElementById('added-to-pcp-cart')

    let tradeInModal = new bootstrap.Modal(document.getElementById('trade-in'))
    let addedToCartModal = new bootstrap.Modal(document.getElementById('added-to-cart'))
    let addedToCartErrorModal = new bootstrap.Modal(document.getElementById('added-to-cart_err'))
    let addedToPcpCartModal = new bootstrap.Modal(addedToPcpCartModalElement)


    let addToBasketBtns = document.querySelectorAll('.add-to-basket')
    addToBasketBtns.forEach((addToBasketBtn) => {
        addToBasketBtn.addEventListener('click', (event) => {
            event.preventDefault()
            let id = event.currentTarget.getAttribute('data-item-id')
            if (!event.currentTarget.classList.contains('trade-in')) {
                AddToCart(id)
            } else {
                let tradeInAmount = event.currentTarget.getAttribute('data-trade-in')
                TradeIn(id, tradeInAmount)
            }
            return false
        })
    })

    let addSoftbundleToBasketBtns = document.querySelectorAll('.add-softbundle-to-basket')
    addSoftbundleToBasketBtns.forEach((addSoftbundleToBasketBtn) => {
        addSoftbundleToBasketBtn.addEventListener('click', (event) => {
            event.preventDefault()
            let id = event.currentTarget.getAttribute('data-softbundle-id')
            if (!event.currentTarget.classList.contains('trade-in')) {
                AddToCartSoftbundle(id)
            } else {
                let tradeInAmount = event.currentTarget.getAttribute('data-trade-in')
                TradeIn(id, tradeInAmount)
            }
            return false
        })
    })

    let addToBasketTradeInBtns = document.querySelectorAll('.add-to-basket-trade-in')
    addToBasketTradeInBtns.forEach((addToBasketTradeInBtn) => {
        addToBasketTradeInBtn.addEventListener('click', (event) => {
            event.preventDefault()
            let id = event.currentTarget.getAttribute('data-item-id')
            let tradeInBrand = document.querySelector('[name="trade-in-brand"]').value
            let tradeInName = document.querySelector('[name="trade-in-name"]').value
            let tradeInSerial = document.querySelector('[name="trade-in-serial"]').value
            let tradeInContractor = document.querySelector('[name="trade-in-contractor"]').value

            if (!tradeInBrand || !tradeInName || !tradeInSerial) {
                alert("Es müssen alle Angaben zum Eintausch-Artikel gemacht werden.");
            } else {
                tradeInModal.hide()
                AddToCartTradeIn(id, {brand: tradeInBrand, name: tradeInName, serial: tradeInSerial, contractor: tradeInContractor});
            }
            return false;
        })
    })

    let tradeInContractorChecks = document.querySelectorAll('[name="trade-in-contractor"]')
    tradeInContractorChecks.forEach((tradeInContractorCheck) => {
        tradeInContractorCheck.addEventListener('change', (event) => {
            event.preventDefault()
            event.currentTarget.value = !!event.currentTarget.checked;
        })
    })

    let addWsToBasketBtns = document.querySelectorAll('.add-ws-to-basket')
    addWsToBasketBtns.forEach((addWsToBasketBtn) => {
        addWsToBasketBtn.addEventListener('click', (event) => {
            event.preventDefault()
            let id = event.currentTarget.getAttribute('data-item-id')
            AddToCartWS(id);
            return false;
        })
    })

    let addPcpToBasketBtns = document.querySelectorAll('.add-pcp-to-basket')
    addPcpToBasketBtns.forEach((addPcpToBasketBtn) => {
        addPcpToBasketBtn.addEventListener('click', (event) => {
            event.preventDefault()
            let id = event.currentTarget.getAttribute('data-pcp-id')
            AddToCartPCP(id)
            return false
        })
    })

    addedToPcpCartModalElement.addEventListener('hidden.bs.modal', () => {
        document.getElementById('pcp-cart-items').innerHTML = ''
    })

    function TradeInOld(id, discount) {
        document.querySelector('.trade-in-discount').innerHTML = discount
        document.querySelector('.with-trade-in').setAttribute('data-trade-in-discount', discount)
        document.querySelector('.without-trade-in').setAttribute('data-item-id', id)
        document.querySelector('.with-trade-in').setAttribute('data-item-id', id)
        tradeInModal.show()
    }

    /* bf funtion */
    function TradeIn(id, discount) {
        AddToCartTradeIn(id, {brand: 'bf_2019-supress', name: '', serial: '', contractor: ''});
    }


    function AddToCart(id) {
        let demo = document.getElementById('item-' + id).getAttribute('data-demo')
        let url = ''
        if (demo != 'false') {
            url = '/checkout/cart/demo/' + demo + '/add'
        } else {
            url = '/checkout/cart/' + id + '/add'
        }

        axios.post(url, {})
            .then((response) => {
                let itemInfos = response.data
                document.getElementById('added-to-cart-name').innerHTML = itemInfos['name']
                document.getElementById('added-to-cart-image').setAttribute('src', itemInfos['image'])
                document.getElementById('added-to-cart-delivery-color').style.backgroundColor = '#' + itemInfos['deliveryTime']['color']
                document.getElementById('added-to-cart-delivery-text').innerHTML = itemInfos['deliveryTime']['text']

                if (itemInfos.brandInfos) {
                    document.getElementById('cart-gpsr-accordion-container').classList.remove('d-none')

                    document.getElementById('cart-gpsr-manufacturer_name').innerHTML = itemInfos.brandInfos.manufacturer_name
                    document.getElementById('cart-gpsr-manufacturer_address').innerHTML = itemInfos.brandInfos.manufacturer_address
                    document.getElementById('cart-gpsr-manufacturer_email').innerHTML = itemInfos.brandInfos.manufacturer_email
                    document.getElementById('cart-gpsr-manufacturer_website').innerHTML = itemInfos.brandInfos.manufacturer_website

                    document.getElementById('cart-gpsr-responsible_name').innerHTML = itemInfos.brandInfos.responsible_name
                    document.getElementById('cart-gpsr-responsible_address').innerHTML = itemInfos.brandInfos.responsible_address
                    document.getElementById('cart-gpsr-responsible_email').innerHTML = itemInfos.brandInfos.responsible_email
                    document.getElementById('cart-gpsr-responsible_website').innerHTML = itemInfos.brandInfos.responsible_website

                    if (itemInfos.safetyInstructions.length) {
                        document.getElementById('cart-gpsr-instructions').classList.remove('d-none')
                        itemInfos.safetyInstructions.forEach((itm) => {
                            let liElem = document.createElement('li')
                            if (itm.internal_link) {
                                liElem.innerHTML = '<a class="dropdown-item" target="_blank" href="' + itm.internal_link + '">' + itm.country.language + '</a>'
                            } else {
                                liElem.innerHTML = '<a class="dropdown-item" target="_blank" href="//' + itm.external_link + '">' + itm.country.language + '</a>'
                            }
                            document.getElementById('data-gpsr-list').appendChild(liElem)
                        })
                    } else {
                        document.getElementById('cart-gpsr-instructions').classList.add('d-none')
                    }
                } else {
                    document.getElementById('cart-gpsr-accordion-container').classList.add('d-none')
                }

                addedToCartModal.show()
            })
            .finally(() => {
                UpdateCart()
            })
    }

    function AddToCartSoftbundle(id) {
        let url = '/checkout/cart/softbundle/' + id + '/add'
        axios.post(url, {})
            .then((response) => {
                let itemInfos = response.data
                document.getElementById('added-to-cart-name').innerHTML = itemInfos['name']
                document.getElementById('added-to-cart-image').setAttribute('src', itemInfos['image'])
                document.getElementById('added-to-cart-delivery-color').style.backgroundColor = '#' + itemInfos['deliveryTime']['color']
                document.getElementById('added-to-cart-delivery-text').innerHTML = itemInfos['deliveryTime']['text']

                if (itemInfos.brandInfos) {
                    document.getElementById('cart-gpsr-accordion-container').classList.remove('d-none')

                    document.getElementById('cart-gpsr-manufacturer_name').innerHTML = itemInfos.brandInfos.manufacturer_name
                    document.getElementById('cart-gpsr-manufacturer_address').innerHTML = itemInfos.brandInfos.manufacturer_address
                    document.getElementById('cart-gpsr-manufacturer_email').innerHTML = itemInfos.brandInfos.manufacturer_email
                    document.getElementById('cart-gpsr-manufacturer_website').innerHTML = itemInfos.brandInfos.manufacturer_website

                    document.getElementById('cart-gpsr-responsible_name').innerHTML = itemInfos.brandInfos.responsible_name
                    document.getElementById('cart-gpsr-responsible_address').innerHTML = itemInfos.brandInfos.responsible_address
                    document.getElementById('cart-gpsr-responsible_email').innerHTML = itemInfos.brandInfos.responsible_email
                    document.getElementById('cart-gpsr-responsible_website').innerHTML = itemInfos.brandInfos.responsible_website

                    if (itemInfos.safetyInstructions.length) {
                        document.getElementById('cart-gpsr-instructions').classList.remove('d-none')
                        itemInfos.safetyInstructions.forEach((itm) => {
                            let liElem = document.createElement('li')
                            if (itm.internal_link) {
                                liElem.innerHTML = '<a class="dropdown-item" target="_blank" href="' + itm.internal_link + '">' + itm.country.language + '</a>'
                            } else {
                                liElem.innerHTML = '<a class="dropdown-item" target="_blank" href="//' + itm.external_link + '">' + itm.country.language + '</a>'
                            }
                            document.getElementById('data-gpsr-list').appendChild(liElem)
                        })
                    } else {
                        document.getElementById('cart-gpsr-instructions').classList.add('d-none')
                    }
                } else {
                    document.getElementById('cart-gpsr-accordion-container').classList.add('d-none')
                }

                addedToCartModal.show()
            })
            .finally(() => {
                UpdateCart()
            })
    }

    function AddToCartTradeIn(id, tradeIn) {
        let demo = document.getElementById('item-' + id).getAttribute('data-demo')
        let url = ''
        if (demo != 'false') {
            url = '/checkout/cart/demo/' + demo + '/add'
        } else {
            url = '/checkout/cart/' + id + '/add'
        }

        axios.post(url, { trade_in: tradeIn })
            .then((response) => {
                let itemInfos = response.data
                document.getElementById('added-to-cart-name').innerHTML = itemInfos['name']
                document.getElementById('added-to-cart-image').setAttribute('src', itemInfos['image'])
                addedToCartModal.show()
            })
            .finally(() => {
                UpdateCart()
            })
    }

    function AddToCartWS(id) {
        axios.post('/checkout/cart/workshop/' + id + '/add', {})
            .then((response) => {
                let itemInfos = response.data
                if (itemInfos.status.error) {
                    document.querySelector('#added-to-cart_err [data-error="' + itemInfos.status.code + '"]')
                    addedToCartErrorModal.show()
                } else {
                    document.getElementById('added-to-cart-name').innerHTML = itemInfos['name']
                    document.getElementById('added-to-cart-image').setAttribute('src', itemInfos['image'])
                    addedToCartModal.show()
                }
            })
            .finally(() => {
                UpdateCart()
            })
    }

    function AddToCartPCP(id) {
        axios.post('/checkout/cart/pcp/' + id + '/add', {})
            .then((response) => {
                let itemInfos = response.data
                let htmlTemplate = '';
                for (let i = 0; i < itemInfos.length; i++) {
                    htmlTemplate += '<div class="row align-items-center">' + '<div class="col-3 text-center">';
                    if (itemInfos[i]['image'] !== null) {
                        htmlTemplate += '<img data-src="' + itemInfos[i]['image'] + '" alt="" class="lazy img-fluid added-to-cart-image"/>';
                    } else {
                        htmlTemplate += itemInfos[i]['icon'];
                    }
                    htmlTemplate += '</div><div class="col-9"><p class="font-weight-bold mb-0">' + itemInfos[i]['name'] + '</p></div></div>';
                }
                document.getElementById('pcp-cart-items').append(htmlTemplate)
                addedToPcpCartModal.show()
            })
            .finally(() => {
                UpdateCart()
            })
    }

    function UpdateCart() {
        let cartQuantityBadge = document.querySelector('.cart-quantity-badge')
        axios.post('/checkout/cart/update/header/v2', {})
            .then((response) => {
                cartQuantityBadge.innerHTML = response.data.quantity
                cartQuantityBadge.classList.remove('d-none')
            })
    }

    let multiselectElements = document.querySelectorAll('.multiselect')
    multiselectElements.forEach((multiselectElement) => {
        multiselectElement.addEventListener('change', (event) => {
            let target = event.target.getAttribute('data-bs-target')
            document.querySelectorAll('.' + target).checked = !!event.target.checked;
        })
    })

    let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    })


    let lazyLoadInstance = new LazyLoad({});

})

window.sct = (data) => {
    let ttl = 1000*60*60*24*30
    let a = localStorage.getItem("soluteclid")
    if (!a) return
    let b = a.split(" ", 2)
    if (parseInt(b[0])+ttl > (new Date()).getTime()) {
        let url = "https://cmodul.solutenetwork.com/conversion"
        url += "?val=" + encodeURIComponent(data.val)
        url += "&oid=" + encodeURIComponent(data.oid)
        url += "&factor=1"
        url += "&url=" + encodeURIComponent(b[1])
        let req = new XMLHttpRequest()
        req.open("GET", url)
        req.send()
    } else {
        localStorage.removeItem("soluteclid")
    }
}

import './modules/_scrolltop.js'
import './modules/_mobileNav.js'
import './modules/_scrollNav.js'
