window.domReady(() => {
    let scrollBtn = document.getElementById("scrolltop-btn")

    window.addEventListener('scroll', function() {
        scrollFunction()
    });

    function scrollFunction() {

        if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
            scrollBtn.style.display = "flex"
            scrollBtn.dataset.set = 'FLEX'
        } else {
            scrollBtn.style.display = "none"
        }
    }

    scrollBtn.addEventListener("click", function topFunction() {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
    });
})


