window.domReady(() => {
    const mobileNavContainer = document.getElementById('mobileNavigation')
    const mobileNavigationOpener = document.getElementById('mobileNavigationOpener')
    const mobileNavigationCloser = document.getElementById('mobileNavigationCloser')

    mobileNavigationOpener.addEventListener('click', () => {
        toggleMobileNav()
    })

    mobileNavigationCloser.addEventListener('click', () => {
        toggleMobileNav(true)
    })

    function toggleMobileNav(wantClose = false) {
        const body = document.querySelector('body')
        const nav = document.getElementById('mobileNavigation')
        const navTop = document.getElementById('mobileNavTop')
        const navContent = document.getElementById('mobileNavContent')
        const height = window.innerHeight - navTop.offsetHeight
        navContent.style.height = `${height}px`

        nav.style.display = 'block'
        // Überprüfen, ob das Menü geöffnet oder geschlossen ist
        if (mobileNavContainer.style.left === "0%") {
            // Menü schließen
            body.style.removeProperty('overflow')
            mobileNavContainer.style.left = "-100%"
        } else {
            // Menü öffnen
            if (!wantClose) {
                body.style.overflow = 'hidden'
                mobileNavContainer.style.left = "0%"
            }
        }
    }


    let startX

    mobileNavContainer.addEventListener('touchstart', e => {
        startX = event.touches[0].clientX;
    })
    mobileNavContainer.addEventListener('touchmove', e => {
        handleTouchMove(e)
    })

    function handleTouchMove(event) {
        if (!startX) return

        const currentX = event.touches[0].clientX
        const deltaX = startX - currentX

        if ( deltaX > 100) {
            toggleMobileNav(true)
        }
    }
})
